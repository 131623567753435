<template>
  <div class="page-container">
    <div class="sidebar-menu toggle-others fixed">
      <div class="sidebar-menu-inner">
        <header class="logo-env">
          <!-- logo -->
          <div class="logo">
            <div style="font-size: 20px; font-weight: bold;color: #fff;">梦屿千寻导航站</div>
            <!-- <a href="javascript:void(0)" class="logo-expanded">
              <img src="../assets/images/logo@2x.png" width="100%" alt="" />
            </a> -->
            <!-- <a href="javascript:void(0)" class="logo-collapsed">
              <img
                src="../assets/images/logo-collapsed@2x.png"
                width="40"
                alt=""
              />
            </a> -->
          </div>
          <div class="mobile-menu-toggle visible-xs">
            <a href="javascript:void(0)" data-toggle="user-info-menu">
              <i class="linecons-cog"></i>
            </a>
            <a href="javascript:void(0)" data-toggle="mobile-menu">
              <i class="fa-bars"></i>
            </a>
          </div>
        </header>
        <!-- 侧边栏 -->
        <ul id="main-menu" class="main-menu">
          <li v-for="(menu, idx) in items" :key="idx">
            <a :href="'#' + transName(menu)" class="smooth">
              <i :class="menu.icon"></i>
              <span class="title">{{ transName(menu) }}</span>
            </a>
            <ul v-if="menu.children">
              <li v-for="(submenu, idx) in menu.children" :key="idx">
                <a :href="'#' + transName(submenu)" class="smooth">
                  <span class="title">{{ transName(submenu) }}</span>
                  <span v-show="submenu.is_hot" class="label label-pink pull-right hidden-collapsed">Hot</span>
                </a>
              </li>
            </ul>
          </li>
          <!-- 关于本站 -->
          <!-- <li class="submit-tag">
            <router-link to="/about">
              <i class="linecons-heart"></i>
              <span class="tooltip-blue">关于本站</span>
              <span class="label label-Primary pull-right hidden-collapsed"
                >♥︎</span
              >
            </router-link>
          </li> -->
        </ul>
      </div>
    </div>

    <div class="main-content">
      <nav class="navbar user-info-navbar" role="navigation">

        <ul class="user-info-menu left-links list-inline list-unstyled">
          <li class="hidden-sm hidden-xs">
            <a href="javascript:void(0)" data-toggle="sidebar"><i class="fa-bars"></i></a>
          </li>
          <li class="dropdown hover-line language-switcher">
            <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
              <img :src="lang.flag" /> {{ lang.name }}
            </a>
            <ul class="dropdown-menu languages">
              <li :class="{ active: langItem.key === lang.key }" v-for="langItem in langList" :key="langItem.key">
                <a href="javascript:void(0)" @click="lang = langItem">
                  <img :src="langItem.flag" /> {{ langItem.name }}
                </a>
              </li>
            </ul>
          </li>

        </ul>
        <!-- <ul class="user-info-menu right-links list-inline list-unstyled">
          <li class="hidden-sm hidden-xs">
            <a href="https://github.com/Anjaxs/WebStack-vue" target="_blank">
              <i class="fa-github"></i> GitHub
            </a>
          </li>
        </ul> -->
      </nav>
      <div class="search-box">
        <div class="search-wrapper">
          <input type="text" class="search-input" v-model="searchText" @keyup.enter="doSearch" placeholder="输入关键词搜索...">
          <button class="search-btn" @click="doSearch">
            <i class="fa fa-search"></i>
            搜索
          </button>
        </div>
      </div>
      <div class="weather-info-list">
        <div class="weather-info item" v-if="weather">
          <img
            src="https://flyzxf.cn/sun.png"
            alt="weather icon" />
          <span>{{ weather.location.name }}: {{ weather.now.text }}, {{ weather.now.temperature }}°C</span>
        </div>
        <div v-else>
          Loading Hangzhou weather...
        </div>
        <div class="shaoxing-weather-info item" v-if="shaoxingWeather">
          <img
            src="https://flyzxf.cn/sun.png"
            alt="weather icon" />
          <span>{{ shaoxingWeather.location.name }}: {{ shaoxingWeather.now.text }}, {{
            shaoxingWeather.now.temperature }}°C</span>
        </div>
        <div v-else>
          Loading Shaoxing weather...
        </div>
      </div>
  


    <div v-for="(item, idx) in items" :key="idx">
      <div v-if="item.web">
        <WebItem :item="item" :transName="transName" />
      </div>
      <div v-else v-for="(subItem, idx) in item.children" :key="idx">
        <WebItem :item="subItem" :transName="transName" />
      </div>
    </div>

    <Footer />
  </div>
  </div>
</template>

<script>
import WebItem from "../components/WebItem.vue";
import Footer from "../components/Footer.vue";
import itemsData from "../assets/data.json";
import { loadJs } from '../assets/js/app.js'

export default {
  name: "Index",
  components: {
    WebItem,
    Footer,
  },
  data() {
    return {
      searchText: '',
      items: itemsData,
      lang: {},
      langList: [
        {
          key: "zh",
          name: "简体中文",
          flag: "./assets/images/flags/flag-cn.png",
        },
        {
          key: "en",
          name: "English",
          flag: "./assets/images/flags/flag-us.png",
        },
      ],
      weather: null,
      shaoxingWeather: null,

    };
  },
  created() {
    this.lang = this.langList[0];
    loadJs();
    this.fetchWeather();
    setTimeout(this.fetchShaoxingWeather, 1000); // Delay the request by 1 second
  },
  methods: {
    doSearch() {
      if (this.searchText.trim()) {
        window.open(`https://www.baidu.com/s?wd=${encodeURIComponent(this.searchText.trim())}`)
      }
    },
    transName(webItem) {
      return this.lang.key === "en" ? webItem.en_name : webItem.name;
    },
    async fetchWeather() {
      try {
        const response = await fetch('https://api.seniverse.com/v3/weather/now.json?key=SMaPuMVF4IicVWOpa&location=WTMKQ069CCJ7&language=zh-Hans&unit=c');
        const data = await response.json();
        this.weather = data.results[0];
      } catch (error) {
        console.error('Error fetching weather data:', error);
      }
    },
    async fetchShaoxingWeather() {
      try {
        const response = await fetch('https://api.seniverse.com/v3/weather/now.json?key=SMaPuMVF4IicVWOpa&location=WTMDXTK6STK4&language=zh-Hans&unit=c');
        const data = await response.json();
        this.shaoxingWeather = data.results[0];
      } catch (error) {
        console.error('Error fetching Shaoxing weather data:', error);
      }
    },
  },
};
</script>

<style scoped>
.search-box {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(26, 26, 26, .1);
  margin-bottom: 20px;
}

.search-wrapper {
  display: flex;
  align-items: center;
  max-width: 640px;
  margin: 0 auto;
}

.search-input {
  flex: 1;
  height: 40px;
  padding: 0 15px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
  transition: all 0.3s;
}

.search-input:focus {
  border-color: #2d8cf0;
  box-shadow: 0 0 0 2px rgba(45, 140, 240, .2);
  outline: none;
}

.search-btn {
  height: 40px;
  padding: 0 20px;
  font-size: 14px;
  color: #fff;
  background: #2d8cf0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.search-btn:hover {
  background: #57a3f3;
}

.search-btn i {
  margin-right: 5px;
}

.weather-info {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 60px;
    height: 60px;
  }
}

.shaoxing-weather-info {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 60px;
    height: 60px;
  }
}

.weather-info-list {
  display: flex;
  justify-content: start;
  border: 1px solid #f2f2f2;
  padding: 10px;
  margin-bottom: 20px;
  background: #fff;
  .item {
    margin-right: 20px;
  }

}
</style>